import { useMediaQuery } from 'react-responsive';

import screenBreakpoints from '../../constants/screenBreakpoints';
import { useClientHasRendered } from '../hooks';

/*
  Checks for the width of the screen that the user sees
  After client has rendered.

  Used where we want to change things to look a certain way on screen.
  e.g. showing a specific number of pills, heading size etc 
*/

// These number represent the lowest size required to be part of that category;
export const SMALL = Number(screenBreakpoints.sm.replace('px', ''));
export const MEDIUM = Number(screenBreakpoints.md.replace('px', ''));
export const LARGE = Number(screenBreakpoints.lg.replace('px', ''));
export const XL = Number(screenBreakpoints.xl.replace('px', ''));

export const useIsXLDesktopScreenWidthOrWider = (): ReturnType<typeof useMediaQuery> => {
  const clientHasRendered = useClientHasRendered();
  const isMediaQuery = useMediaQuery({ minWidth: XL });
  return clientHasRendered && isMediaQuery;
};

export const useIsLargeDesktopScreenWidthOrWider = (): ReturnType<typeof useMediaQuery> => {
  const clientHasRendered = useClientHasRendered();
  const isMediaQuery = useMediaQuery({ minWidth: LARGE });
  return clientHasRendered && isMediaQuery;
};

export const useIsDesktopScreenWidthOrWider = (): ReturnType<typeof useMediaQuery> => {
  const clientHasRendered = useClientHasRendered();
  const isMediaQuery = useMediaQuery({ minWidth: MEDIUM });
  return clientHasRendered && isMediaQuery;
};

export const useIsTabletScreenWidthOrWider = (): ReturnType<typeof useMediaQuery> => {
  const clientHasRendered = useClientHasRendered();
  const isMediaQuery = useMediaQuery({ minWidth: SMALL });
  return clientHasRendered && isMediaQuery;
};

export const useIsTabletScreenWidthOrThinner = (): ReturnType<typeof useMediaQuery> => {
  const clientHasRendered = useClientHasRendered();
  const isMediaQuery = useMediaQuery({ maxWidth: MEDIUM - 1 });
  return clientHasRendered && isMediaQuery;
};

export const useIsMobileScreenWidthOrThinner = (): ReturnType<typeof useMediaQuery> => {
  const clientHasRendered = useClientHasRendered();
  const isMediaQuery = useMediaQuery({ maxWidth: SMALL - 1 });
  return clientHasRendered && isMediaQuery;
};
