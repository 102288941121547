import React from 'react';

import { DEFAULT_ICON_PROPS, IIconProps } from './constants';

export const ArrowRightIcon = ({ ariaLabel = '', ...props }: IIconProps) => {
  const { size, width, height, title, ariaHidden, className } = {
    ...DEFAULT_ICON_PROPS,
    ...props,
  };
  return (
    <svg
      width={size || width}
      height={size || height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      aria-labelledby={ariaLabel}
      aria-hidden={ariaHidden}
      className={`block ${className}`}
      {...props}
    >
      {title && <title>{title}</title>}
      <path
        d="M5 12L19 12"
        className="stroke-current"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12 5L19 12L12 19"
        className="stroke-current"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
