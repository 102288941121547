/* eslint-disable no-magic-numbers */

import { QRCodeSVG } from 'qrcode.react';
import ContentLoader from 'react-content-loader';
import { twMerge } from 'tailwind-merge';

import { useAnalytics } from '../../contexts/AnalyticsContext/AnalyticsContext';
import { useClientHasRendered } from '../../helpers/hooks';
import { useIsMobileDevice } from '../../helpers/hooks/useIsMobileDevice';
import { AppDownloadUrlSourceType, getAppDownloadUrl } from '../../helpers/url/getAppDownloadUrl';

import blackFish from './assets/Black_mono_fish.png';
import { EventMykissCtaButtonClicked } from '../../interfaces/events';

/**
 * QRCode component renders a QR code with customizable properties.
 * The QR code will encode a URL, which can be provided via the `link` prop. If no link is provided, a default app download link will be used.
 * By default, the QR code will be hidden on mobile devices, but this can be overridden via the `showOnMobileDevice` prop.
 * A loading spinner will be shown until the device detection on the client has been completed to avoid CLS.
 *
 * @param {Object} props - The properties object.
 * @param {string} [props.link] - The URL to be encoded in the QR code. If not provided, a default app download link will be used.
 * @param {number} [props.size=125] - The size of the QR code.
 * @param {number} [props.imageSize=29] - The size of the image in the center of the QR code.
 * @param {'L' | 'M' | 'Q' | 'H'} [props.level='M'] - The error correction level of the QR code.
 * @param {'#FFFFFF' | '#F5F6F7'} [props.bgColor='#FFFFFF'] - The background color of the QR code.
 * @param {string} [props.fgColor='#000000'] - The foreground color of the QR code.
 * @param {string} [props.className] - Additional class names for the component.
 * @param {boolean} [props.showOnMobileDevice=false] - Whether to show the QR code on mobile devices.
 * @param {EventMykissCtaButtonClicked['properties']['source']} props.source - A unique id for the location where the AppStoreButtons are used, e.g., "waters_sidebar", "blog_post_footer".
 *
 * @remarks
 * Make sure you test that the QR code will scan if you change the default size,
 * as the picture removes some of the readable area and if you lose too much the
 * code doesn't work
 *
 * @returns {JSX.Element} The QRCode component.
 */
export const QRCode = ({
  link,
  size = 125,
  imageSize = 29,
  level = 'M',
  bgColor = '#FFFFFF',
  fgColor = '#000000',
  className,
  showOnMobileDevice = false,
  source,
}: {
  link?: string;
  size?: number;
  imageSize?: number;
  level?: 'L' | 'M' | 'Q' | 'H';
  bgColor?: '#FFFFFF' | '#F5F6F7';
  fgColor?: string;
  className?: string;
  showOnMobileDevice?: boolean;
  /* A unique id for the location where the AppStoreButtons are used is, e.g. "waters_sidebar", "blog_post_footer" */
  source: EventMykissCtaButtonClicked['properties']['source'];
}) => {
  const clientHasRendered = useClientHasRendered();
  const isMobileDevice = useIsMobileDevice();
  const { currentPage } = useAnalytics();

  const defaultAppDownloadLink = getAppDownloadUrl({
    appDownloadSourceType: AppDownloadUrlSourceType.QrCode,
    appDownloadSource: source,
    currentPageId: currentPage,
  });

  if (!showOnMobileDevice && isMobileDevice) {
    return <></>;
  }

  return (
    <div className={twMerge(showOnMobileDevice ? 'flex' : 'hidden sm:flex', className)}>
      {clientHasRendered ? (
        <QRCodeSVG
          value={link ?? defaultAppDownloadLink}
          size={size}
          level={level}
          bgColor={bgColor}
          fgColor={fgColor}
          imageSettings={{
            src: blackFish.src,
            height: imageSize,
            width: imageSize,
            excavate: true,
          }}
          data-testid="DownloadAppQRCode"
        />
      ) : (
        <ContentLoader
          uniqueKey="qrcode-loader-key"
          className="flex items-center justify-center"
          style={{
            minWidth: `${size}px`,
            minHeight: `${size}px`,
            height: `${size}px`,
            width: `${size}px`,
          }}
        >
          <rect x="0" y="0" rx="5" ry="5" width="220" height={size} />
        </ContentLoader>
      )}
    </div>
  );
};
