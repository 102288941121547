import React from 'react';

import { NextPageWithLayout } from 'next';

import { ShopLandingFeature } from './components/ShopLandingFeature';
import { ShopLandingHeader } from './components/ShopLandingHeader';
import { ShopLandingQuestion } from './components/ShopLandingQuestions';
import { MetaTags } from '../../components/MetaTags/MetaTags';
import heroMobile from './assets/heroMobile.png';
export interface IShopLandingPage {
  appPage?: boolean;
}

export const ShopLandingPage: NextPageWithLayout<IShopLandingPage> = ({ appPage = false }) => (
  <div>
    <MetaTags
      title="Fishbrain marketplace"
      link="/shop"
      description="Our marketplace may be closed, but we’ve still got you covered."
      image={heroMobile.src}
    />
    <ShopLandingHeader appPage={appPage} />
    {!appPage && <ShopLandingFeature />}
    <ShopLandingQuestion />
  </div>
);
