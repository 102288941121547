export const sm = '600px';
export const md = '990px';
export const lg = '1280px';
export const xl = '1680px';

export default {
  sm,
  md,
  lg,
  xl,
};
